var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Login base-height" }, [
      _c("div", { staticClass: "mainBox" }, [
        _c(
          "h1",
          [
            _c("router-link", { attrs: { to: "/" } }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/reviewmind/logo.png"),
                  alt: "로고"
                }
              })
            ])
          ],
          1
        ),
        _c("div", { staticClass: "form login" }, [
          _c("form", [
            _c("fieldset", [
              _c("legend", [_vm._v("로그인")]),
              _c("div", { staticClass: "cover bg" }, [
                _c("label", [_vm._v("이메일")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  attrs: { type: "email", placeholder: "이메일 입력" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "cover bg" }, [
                _c("label", [_vm._v("이름")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.username,
                      expression: "username"
                    }
                  ],
                  attrs: { type: "text", placeholder: "이름 입력" },
                  domProps: { value: _vm.username },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.username = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "button",
                {
                  staticClass: "btn full primary",
                  attrs: { type: "button" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 비밀번호 찾기 ")]
              )
            ])
          ])
        ])
      ]),
      _c(
        "aside",
        { staticClass: "flexL" },
        [
          _c("router-link", { staticClass: "btn", attrs: { to: "/login" } }, [
            _vm._v("로그인")
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }